.header {
  background: $color-header;
  color: $color-white;
}

.headerInner {
  padding: $spacing-normal;
  @include prefix(display, flex);
  @include prefix(justify-content, space-between);
  @include prefix(align-items, center);
  -ms-flex-pack: justify;
  flex-grow: 0;
  @include onDesktop() {
    align-items: flex-end;
  }
}

.header a {
  color: rgba($color-white, 0.75);
  text-decoration: none;
  &:hover,
  &:focus {
    color: $color-white;
  }
  &:focus {
    outline-width: 1px;
    outline-color: $color-link-focus-outline-header;
    outline-style: solid;
  }
}

.logoImage {
  max-width: 100%;
  height: auto;
  margin-left: 1em;
}

.logoLink {
  display: inline-block;
  @include onDesktop() {
    width: 260px;
  }
  width: 154px;
}
