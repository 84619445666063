@mixin extendToEdges($color) {
    position: relative;
    &:before, &:after {
        display: block;
        content: " ";
        background: $color;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
    }
    &:before {
        left: -100vw;
    }
    &:after {
        right: -100vw;
    }
}
