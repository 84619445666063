// PopOvers
// 
// For toggle boxes and popovers, these helper classes help you
// hide some sort of box by default, and show it on either hover
// or clicking a toggle element.
// 
// Two uses:
// 1) A toggle box, like the nav menu
// 2) A hover popover
// 
// Please note that the order of these elements is the only thing associating them.
// So .popOver must go before .popOverLabel must go before .popOverPanel when using
// it as a toggle; .popOver.popOver--hover must go before .popOverPanel when using
// it as a hover.
// 
// Markup:
// <input class="popOver" name="myPopOver" id="myPopOver" />
// <label class="popOverLabel" for="myPopOver">Click me to toggle the popover</label>
// <div class="popOverPanel">
//  I'm hidden by default, but when you click the element .popOverLabel, it toggles
//  this on and off, closing it when the user clicks off.
// </div>
// <a href="#" class="popOver popOver--hover">Popover on hover</a>
// <div class="popOverPanel">
//  I'm hidden by default, and popover when you hover over the .popOver--hover element
//  directly preceding this!
// </div>

.popOver {
    position:absolute;
    opacity: 0;
    animation-fill-mode: forwards;
}

.popOverPanel {
    display: none;
    animation-fill-mode: forwards;
}

.popOver.popOver--hover {
    display: block;
    animation-fill-mode: forwards;
}

.popOverLabel {
    cursor: pointer;
}

.popOver:checked + .popOverLabel + .popOverPanel,
.popOverPanel:hover {
    display: block;
    animation-duration: .25s;
    animation-name: popOverShow;
    animation-timing-function: $animation-cubic-bezier;
}
