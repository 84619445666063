h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    font-family: $font-stack-heading;
    margin: $spacing-normal 0;
    line-height: $line-height-heading;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

h1 {
    font-size: $font-size-largest;
}

h2 {
    font-size: $font-size-larger;
}

h3 {
    font-size: $font-size-large;
}

h4 {
    font-size: $font-size-normal;
}

h5 {
    font-size: $font-size-small;
    text-transform: uppercase;
}

h6 {
    font-size: $font-size-smallest;
    text-transform: uppercase;
}

p {
    margin: $spacing-normal 0;
}

b, strong {
    font-weight: bold;
}

em, i {
    font-style: italic;
}

ul, ol {
    margin: $spacing-normal 0;
    padding: 0 $spacing-large;
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

li + li {
    margin-top: $spacing-small;
}

code {
    background: $color-gray-light;
    font-size: $font-size-small;
    padding: $spacing-small;
}

pre code {
    display: block;
    overflow-x: auto;
    padding: $spacing-normal;
}
