.relatedProducts {
    margin-top: $spacing-large;
    padding-top: $spacing-large;
    border-top: 1px solid $color-gray-light;
    h3 {
        margin-bottom: 0;
        @include onDesktop() {
            padding: 0 $spacing-normal $spacing-small;
        }
    }
    .productGrid {
        margin-top: 0;
    }
    .productBlock {
        margin-top: 0;
    }
    @include onDesktop() {
        .productGrid__product {
            max-width: 25vw;
            min-width: 250px;
        }
    }
}
