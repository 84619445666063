@include onDesktop() {
  .i18n {
    position: absolute;
    top: -50px;
    right: 0px;
  }
}

.i18n-flag-link {
  background: rgba($color-white, 0.25);
  padding: 3px;
  border-radius: 3px;
}

.i18n-language {
  vertical-align: top;
  @include onDesktop() {
    display: none;
  }
}
