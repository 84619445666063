.navigationBox {
    @include onDesktop() {
        position: relative;
        .popOver, .popOverLabel {
            display: none;
        }
    }
}

.popOverLabel--navigation {
    @include prefix(display, flex);
    @include prefix(align-items, center);
}

.navigationCloseImage,
.navigationOpenImage {
    max-width: 100%;
    width: 25px;
    height: auto;
    margin-left: $spacing-small;
}

.popOver + .popOverLabel--navigation .navigationCloseImage {
    display: none;
}

.popOver:checked + .popOverLabel--navigation {
    .navigationOpenImage {
        display: none;
    }
    .navigationCloseImage {
        display: block;
    }
}

.hasChildrenCarrot {
    display: none;
    width: 11px;
    height: auto;
    @include onDesktop() {
        display: inline-block;
    }
}

.navigation {
    position: absolute;
    background: $color-main;
    @include height(10);
    color: $color-white;
    top: 4rem;
    right: 0;
    left: 0;
    max-width: 100vw;
    padding: 0;
    list-style-type: none;
    a, a:visited {
        font-size: $font-size-large;
        color: $color-white,;
        text-decoration: none;
        display: block;
        padding: $spacing-small;
        &:hover, &:focus {
            color: $color-white;
            text-decoration: underline;
        }
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding-left: $spacing-normal;
        margin: 0;
    }
    > li {
        border-top: 1px solid rgba($color-white, .5);
        @include onDesktop() {
            border-top: 0;
        }
    }
    .has-children > a {
        color: $color-gray-light;
        @include onDesktop() {
            color: $color-white;
        }
    }
}

@include onDesktop() {
    .popOver + .popOverLabel + .navigation,
    .popOver:checked + .popOverLabel + .navigation,
    .navigation:hover {
        display: flex;
        margin: 0;
        position: static;
        animation: none;
        align-items: bottom;
        > li + li {
            margin-left: $spacing-small;
        }
    }
    .navigation a {
        padding: $spacing-smaller;
    }
    .navigation .has-children {
        position: relative;
        cursor: pointer;
        > ul {
            line-height: $spacing-nav-line-height;
            display: none;
            position: absolute;
            background: $color-header;
            right: -$spacing-yuge;
            left: 0;
            a {
                padding: 3px;
                font-size: $font-size-normal;
            }
        }
        &:hover > ul,
        &:focus > ul,
        &:active > ul {
            display: block;
        }
    }
}
