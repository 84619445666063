$spacing-normal: 1rem;

$spacing-large: $spacing-normal * 1.5;
$spacing-larger: $spacing-normal * 2;
$spacing-huge: $spacing-normal * 3;
$spacing-yuge: $spacing-normal * 14;

$spacing-small: $spacing-normal * .5;
$spacing-smaller: $spacing-normal * .25;

$spacing-nav-line-height: 2.827rem;