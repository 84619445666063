@mixin prefix($property, $value) {
    $prefixes: ms webkit o moz;

    @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
}
