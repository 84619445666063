.productSku {
    display: block;
    text-transform: uppercase;
    font-size: $font-size-base;
    color: $color-gray;
    margin-top: -#{$spacing-normal};
}

.productSku--relatedProduct {
    padding: $spacing-normal;
}

.productManuals {
    margin: $spacing-large 0 0;
    font-size: $font-size-large;
    font-weight: bold;
}

.productFeatures,
.productIncludes {
    margin-top: $spacing-large;
    min-width: 50%;
    flex-grow: 1;
}

@include onDesktop($layout-breakpoint-pdp) {
    .productBlock--pdp {
        vertical-align: top;
        @supports(display: flex) {
            display: flex;
        }
    }
    .productBlock__media {
        min-width: 40%;
        max-width: 100%;
        // Fix for IE 11
        overflow: visible;
        @include onDesktop() {
            vertical-align: top;
            display: inline-block;
        }
        @supports(display: flex) {
            width: auto;
        }
    }
    .productBlock__info {
        @include onDesktop() {
            vertical-align: top;
            display: inline-block;
            width: 59%;
            padding-left: $spacing-large;
        }
        @supports(display: flex) {
            width: auto;
            flex-grow: 1;
        }
    }
    .productBlock__info__details {
        @supports(display: flex) {
            display: flex;
        }
    }
}
