.productBlock__featureIcons {
    margin: $spacing-large 0 0;
    display: flex;
    justify-content: space-between;
    @include onDesktop() {
        justify-content: flex-start;
    }
}

.featureIcon {
    display: flex;
    flex-direction: column;
    text-align: center;
    @include onDesktop() {
        + .featureIcon {
            margin-left: $spacing-huge;
        }
    }
}

.featureIcon__text {
    margin-top: $spacing-small;
    font-weight: bold;
}
