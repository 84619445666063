.imageMask {
    font-size: 0;
    background: $color-main;
    padding: 2px;
    display: inline-block;
    max-width: 100%;
}

.imageMask img {
    border-top-right-radius: 50vw;
    @include onDesktop() {
        border-top-right-radius: 50%;
    }
    height: auto;
    max-width: 100%;
    transition: .5s border-top-right-radius $animation-cubic-bezier;
}

.imageMask:hover a img, .imageMask:focus a img, .imageMask a img:focus {
    border-top-right-radius: 0;
}

.imageMask a:focus {
    box-shadow: 0 0 5px 2px $color-link-focus-outline-images;
    display: block;
}
