.splashImage {
    max-height: 75vh;
    overflow: hidden;
    width: 100%;
    height: auto;
    img {
        width: 100%;
        height: auto;
    }
    border-bottom: 2px solid $color-main;
    font-size: 0;
}
