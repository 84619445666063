$animation-cubic-bezier: cubic-bezier(.11,.73,.03,1.02);

@keyframes popOverShow {
    0% {
        opacity: 0;
        z-index: auto;
        transform: scale(0, 0);
    }
    100% {
        opacity: 1;
        z-index: auto;
        transform: scale(1, 1);
    }
}
