$color-main: #093266;
$color-secondary: #B80C4B;

$color-white: #fcfcfc;
$color-black: #343741;
$color-gray: #56565A;
$color-gray-light: #B2B5B6;

$color-body-text: $color-black;

$color-link: rgba($color-main, .75);
$color-link-hover: $color-main;

$color-link-focus-outline: rgba($color-main, .5);
$color-link-focus-outline-header: rgba($color-white, .25);
$color-link-focus-outline-footer: rgba($color-white, .25);
$color-link-focus-outline-images: $color-secondary;

$color-header: $color-main;
$color-header-text: $color-white;

$color-footer: $color-main;
$color-footer-text: $color-white;
