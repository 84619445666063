.productGrid {
    margin: $spacing-huge -#{$spacing-normal} 0;
    @include onDesktop() {
        margin: $spacing-huge 0 0;
        @supports(display: flex) {
            display: flex;
        }
    }
}

.productGrid__product {
    padding: 0 $spacing-normal;
    max-width: 100vw;
    margin-top: $spacing-large;
    display: inline-block;
    vertical-align: top;
    @supports(display: flex) {
        display: flex;
        flex-direction: column;
    }
    .productFeatures,
    .productIncludes {
        margin-top: $spacing-small;
    }
    .productTitle {
        margin: $spacing-small 0 0;
    }
    @include onDesktop() {
        max-width: 30%;
        margin-top: 0;
        @supports(display: flex) {
            max-width: 33vw;
        }
    }
}
