.footer {
  padding: $spacing-normal;
  background: $color-footer;
  color: $color-footer-text;
  flex-grow: 0;
  border-top: 2px solid $color-gray-light;
  text-align: center;
  &:before,
  &:after {
    border-top: 2px solid $color-gray-light;
    top: -2px;
  }
  a {
    color: rgba($color-white, 0.75);
    &:hover,
    &:focus {
      color: $color-white;
    }
    &:focus {
      outline-width: 1px;
      outline-color: $color-link-focus-outline-footer;
      outline-style: solid;
    }
  }
}
