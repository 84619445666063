#site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

#body {
    flex-grow: 1;
}

.contentViewport {
    max-width: 100%;
    width: $layout-max-width;
    margin: 0 auto;
    padding: $spacing-normal;
}
