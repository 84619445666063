$font-stack-main: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-stack-heading: "Nunito", $font-stack-main;

$font-size-base: 16px;
$font-size-normal: 1rem;

$font-size-large: 1.438rem;
$font-size-larger: 1.999rem;
$font-size-largest: 2.827rem;

$font-size-small: 0.707rem;
$font-size-smaller: 0.5rem;
$font-size-smallest: 0.354rem;

$line-height-body: 1.4;
$line-height-heading: 1.2;
